import axios from 'axios';
import { UNAUTHORIZE } from '../redux/actions'
import { API_URL } from '../constants/api'

const API = axios.create({
    baseURL: API_URL
})

//API.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("token")}`;
API.defaults.headers.common['X-Platform'] = 'GalleristPanel';
API.defaults.headers.post['Content-Type'] = 'application/json';

export const setupInterceptors = (store) => {
    API.interceptors.request.use(function (config) {
        // console.log('From Request Interceptor');
        config.headers['Authorization'] = `Bearer ${localStorage.getItem("token")}`;
        config.headers['Accept-Language'] = localStorage.getItem('currentLanguage');
        //config.headers['X-Platform'] = 'Admin';
        return config;
    }, function (error) {
        // Do something with request error
        return Promise.reject(error);
    });
    API.interceptors.response.use(function (response) {
        // console.log('From Response Interceptor');
        return response;
    }, function (error) {
        if(error.request.status === 401) {
            localStorage.clear();
            store.dispatch({ type: UNAUTHORIZE });
        }
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error);
    });
}

export default API
